
import SchoolsContractsService from "@/modules/schools-contracts/schools-contracts-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            SchoolsContractsService.create(payload).then(response => {
                resolve({
                    message: "SchoolsContracts criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar SchoolsContracts",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            SchoolsContractsService.update(payload.id,payload).then(response => {
                resolve({
                    message: "SchoolsContracts atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar SchoolsContracts",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            SchoolsContractsService.remove(id).then(response => {
                resolve({
                    message: "SchoolsContracts removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover SchoolsContracts",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            SchoolsContractsService.delete(id).then(response => {
                resolve({
                    message: "SchoolsContracts deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar SchoolsContracts",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            SchoolsContractsService.get(id).then(response => {
                resolve({
                    message: "SchoolsContracts encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar SchoolsContracts",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            SchoolsContractsService.paginate(query).then(response => {
                resolve({
                    message: "SchoolsContracts encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar SchoolsContracts",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            SchoolsContractsService.all().then(response => {
                resolve({
                    message: "SchoolsContracts encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar SchoolsContracts",
                    success: false,
                    error
                });
            })
        })
    }
}
